import { Routes, Route, Link, Navigate } from "react-router-dom";


/** ALL ROUTE ELEMENTS */
import AppHome from "pages/AppHome";
import ContactUs from "pages/public/ContactUs";
import Privacy from "pages/public/Privacy";
import Cookies from "pages/public/Cookies";
import TnCs from "pages/public/TnCs";
import Featured from "pages/public/Featured";
import Interviews from "pages/public/Interviews";
import Popular from "pages/public/Popular";




/** ALL ROUTE ELEMENTS END */


const AllRoutes = () => {
    return ( 
        <>        
            <Routes>
                
                <Route path="/" element={ <AppHome /> } />
                <Route path="/home" element={ <Navigate to='/' /> } />

                <Route path="/featured" element={ <Featured /> } />

                <Route path="/interviews" element={ <Interviews /> } />
                <Route path="/interview" element={ <Navigate to='/interviews' /> } />

                <Route path="/popular" element={ <Popular /> } />
                
                <Route path="/contact-us" element={ <ContactUs /> } />
                <Route path="/contact" element={ <Navigate to='/contact-us' /> } />

                <Route path="/privacy" element={ <Privacy /> } />
                <Route path="/cookies" element={ <Cookies /> } />
                <Route path="/terms-and-condition" element={ <TnCs /> } />
                <Route path="/tncs" element={ <Navigate to='/terms-and-condition' /> } />
                {/* AUTH ROUTES END */}

            </Routes>

        </>
     );
}



 
export default AllRoutes;